import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import ButtonIndex from "../components/button-index"
import RegisterButton from "../components/register-button"

const StyledIndexPageNew = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .index-logo {
    margin: 5rem 0 3rem 0;
    width: 50%;
  }
  .index-button-container {
    display: flex;
    margin-top: 3rem;

    button {
      margin: 0 1rem;
    }
    @media (max-width: 768px) {
      display: inline;
      text-align: center;
      button {
        margin: 1rem 0;
      }
    }
  }
`

const IndexPageNew = () => {
  const image = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo_index_nobg_dotSE_2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout background>
      <StyledIndexPageNew>
        <div className="index-logo">
          <Img fluid={image.logo.childImageSharp.fluid} alt="teamgym.se" />
        </div>
        <div className="index-register-button">
          <RegisterButton />
        </div>
        <div className="index-button-container">
          <div className="single-button-container">
            <Link to="/planner">
              <ButtonIndex>Teamgym planner</ButtonIndex>
            </Link>
          </div>
          <Link to="/calculator">
            <ButtonIndex>CoP calculator</ButtonIndex>
          </Link>
        </div>
      </StyledIndexPageNew>
    </Layout>
  )
}

export default IndexPageNew
