import React, { Component } from "react"
import styled from "styled-components"
import { colors } from "./siteVars"

const StyledButtonIndex = styled.button`
  border: none;
  background: transparent;
  font-size: 1.4rem;
  color: ${colors.white};
  cursor: pointer;
  transition: 200ms ease-in-out;
  &:focus {
    outline: none;
  }
  &:hover {
    transform: translateX(5px);
    img {
      transform: rotateZ(-90deg) translateY(10px);
    }
  }
  img {
    transition: 200ms ease-in-out;
    margin-left: 0.7rem;
    width: 20px;
    filter: invert(1);
    transform: rotateZ(-90deg);
  }
`

export default class ButtonIndex extends Component {
  render() {
    return (
      <StyledButtonIndex>
        {this.props.children}
        <span>
          <img src="/images/arrow-down.png" alt="arrow" />
        </span>
      </StyledButtonIndex>
    )
  }
}
